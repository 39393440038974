$primary: #52921b;

$border: #d2d2d2;

$dialog-background: rgba(147, 196, 250, 0.7);

// Error, warn, success colors in clean, lean, modern style
$error: #e62929;
$warning: #f39c12;
$success: #2ecc71;

// General
$black: #333333;
$white: #ffffff;
$darker-grey: #9e9a9a;
$grey: #f4f7fb;
$darker-white: #fafafb;

// Text
$black-text: #1e1e1e;
$title-black-text: #212121;
$grey-text: #777777;
$primary-text-900: #020c18;

$hover: #616161;
$label-text: #a7a7a7;
